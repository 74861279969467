@use '../../../sass' as *;

.header {
  @include element-background();

  a {
    color: $color-primary-main;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: color 0.25s ease-out, text-shadow 0.25s ease-in;

    &:hover {
      color: $color-primary-hover;
      text-shadow: 0 0 4px rgba(255, 255, 255, 0.25);
      transition: color 0.2s ease-out, text-shadow 0.1s ease-out;
    }

    &:active {
      color: $color-primary-active;
      text-shadow: 0 0 2px rgba(255, 255, 255, 0.4);
    }
  }
}
