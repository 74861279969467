@use '../../sass' as *;

svg {
  fill: $color-primary-main;
  margin: 0.25rem;
  transition: fill 0.25s ease-out;

  &:hover {
    fill: $color-primary-hover;
  }

  &:active {
    fill: $color-primary-active;
  }
}
