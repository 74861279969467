@use './variables' as *;

@mixin bg-gradient_1 {
  background: linear-gradient(320deg, rgba(40, 44, 52, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(120deg, rgba(40, 44, 52, 0.5), rgba(0, 0, 0, 0.5));
}

@mixin element-background(
  $height: 5rem,
  $flex-direction: row
) {
  align-items: center;
  background: linear-gradient(320deg, rgba(40, 44, 52, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(120deg, rgba(40, 44, 52, 0.5), rgba(0, 0, 0, 0.5));
  color: $color-primary-main;
  display: flex;
  font: inherit;
  height: $height;
  flex-direction: $flex-direction;
  justify-content: space-evenly;
  left: 0;
  margin: 0;
  padding: 0.5rem 5rem 0.5rem 5rem;
  position: relative;
  top: 0;
  //box-shadow: 0 0 .75rem rgba(40, 44, 52, 0.25), 0 0 2.5rem 2.5rem rgba(0,0,0,0.25);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3),
    0 0 2.5rem 2.5rem rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 600px) {
    height: 10rem;
    flex-direction: column;
  }
}
