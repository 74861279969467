@use './sass' as *;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Source+Code+Pro:wght@200&display=swap');

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: $primary-font;
  background: $main-bg;
  overflow-x: hidden;
}

#background-illustration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/react-bg.svg") no-repeat top center fixed;
  -webkit-background-size: cover, contain;
  -moz-background-size: cover, contain;
  -o-background-size: cover, contain;
  background-size: cover, contain;
  filter: brightness(0.7) contrast(1.5) hue-rotate(180deg) saturate(5%) opacity(.05) blur(1rem);
}

code {
  font-family: $console-font;
  font-size: 1rem;
}

.scrolled {
  animation: fade-in-bottom 0.6s ease-in-out both;
}

@keyframes fade-in-bottom {
  0% {
    -ms-transform: translateY(50px);
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
