@use './sass' as *;

.App {
  text-align: center;
}

.App-logo {
  //background-image: url("https://avatars.githubusercontent.com/u/98316845?v=4");
  mask-image: radial-gradient(circle at 50%, black 40%, transparent 50%);
  //mask-image: url("../public/logo192.png");
  height: 40vmin;
  pointer-events: none;
  opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color-primary-main;

  p {
    display: inline-flex;
    //flex-direction: row;
    place-items: center;
    //align-items: center;
    //justify-content: center;
    //place-self: baseline;

    code {
      margin: 0.25rem;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  margin: 2rem 0 15rem 0;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 5.25rem rgba(0, 0, 0, 0.8);
  min-height: 25rem;
  text-align: center;
  height: 100%;

  &:nth-child(even) {
    background-color: $color-secondary-main;
    color: $color-primary-hover;
  }

  &:nth-child(odd) {
    background-color: #aaa;
    color: $color-secondary-main;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 5rem;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25) 0%,
      transparent 20%
    );
  }

  &::after {
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.25) 20%,
      transparent 100%
    );
  }

  p {
    color: #b4b8c0;
    margin: 1rem 0 2rem 0;
    padding: 0 5rem 0 5rem;
    line-height: 2;

    @media only screen and (max-width: 600px) {
      padding: 0 1.25rem 0 1.25rem;
    }
  }
}
